.App-footer {
    background-color: var(--color-background);
    bottom: 0;
    padding: 0.6em;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 480px) {
    .row{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.col {
    display: flex;
    margin: auto;
}

.social-media{
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon {
    color: var(--main-color2);
    margin: 0.6em 0.6em;
}

.icon:hover {
    color: #ffe57a;
}

.separator {
    border: 1px solid var(--main-color);
    margin: 0.6em;
}

.copyright {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: gray;
}
