.App-contact-invitation {
    background: linear-gradient(0deg, var(--color-background) 3%, var(--main-color) 100%);
    color: white;
    text-align: center;
    width: 100%;
    font-size: calc(10px + 1vmin);
}
.btn-contact {
    padding: 15px;
    color: var(--main-color);
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 18pt;
    border: 6px solid var(--main-color2);
    border-radius: 25px;
}
