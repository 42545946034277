.App-contact-form {
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 16pt;
}
.message-box {
    min-height: 100px;
    min-width: 90%;
    max-width: 90%;
    margin: 5px;
    font-size: 16pt;
    max-height: 1000px;
    background-color: var(--body-background-color);
    color: gray;
    overflow: hidden;
    border: 2px solid var(--main-color);
    transition: 0.3s;
    resize: none;
}

.message-box:focus,
input:focus {
    outline: none !important;
    box-shadow: 0 0 12px var(--main-color);
    transition: 0.3s;
}

input {
    color: gray;
    width: 90%;
    margin: 5px;
    font-size: 16pt;
    background-color: var(--body-background-color);
    border: 2px solid var(--main-color);
    transition: 0.3s;
}

#counter1 {
    color: gray;
    font-size: 12pt;
    text-align: end;
    margin-top: -9px;
    margin-right: 5%;
}
