.modal__wrap {
    position: fixed;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow-x: hidden;
    background-color: rgba(31, 32, 41, 0.75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
}

.visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.modal {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    display: block;
    width: 40vw;
    height: auto;
    min-height: 100px;
    min-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: var(--body-background-color);
    align-self: center;
    box-shadow: 5px 5px 5px 5px var(--body-background-color);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
}

.visible .modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.close__btn {
    background-color: var(--body-background-color);
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px var(--body-background-color);
}
