.App-collaboration {
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 1vmin);
}

.company-logo {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin: 1em;
    pointer-events: all;
    transition: 2s;
}

.company-logo:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    transition: 0.5s;
}

.company-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0.6em 0.6em;
}

img{
    max-width: 300px;
    height: auto;
}

.curly-brace{
    max-height: 10rem;
}

@media (max-width: 480px) {
    .curly-brace{
        display: none;
    }
}

@media (max-width: 480px) {
    img{
        max-width: 200px;
    }
}