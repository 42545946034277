:root {
    --main-color: #892ca0;
    --main-color2: #e8ba00;
    --body-background-color: #282c34;
    --color-background: #262626;
    --res-xs: 320px;
    --res-s: 480px;
    --res-m: 768px;
    --res-l: 1024px;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-background-color);
    color: white;
}

.container {
    padding: 20px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:link {
    color: lightskyblue;
    background-color: transparent;
    text-decoration: none;
}

.link {
    color: lightskyblue;
    background-color: transparent;
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin: auto;
}

.separator-black {
    border-top: 2px solid gray;
    margin: auto;
    min-width: 250px;
    max-width: 90%;
}

img {
    pointer-events: none;
}

.text-danger {
    color: red;
}

.noselect,
label {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
